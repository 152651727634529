import TestDatingImage from "./Assets/TestDating.jpg";
import styled from 'styled-components/macro'

const PictureView = () => {
	return (
		<PictureContainer>
			<Picture src={TestDatingImage} />
		</PictureContainer>
	)
}

const PictureContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: min(100vw - 40px, 640px);
	height: min(100vh - 40px, 640px);
`;

const Picture = styled.img`
	object-fit: contain;
	width: 100%;
	height: 100%;
`;

export default PictureView;